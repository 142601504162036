<template>
  <transition name="fade">
    <div id="reminder" class="attendance">
      <custom-header :title="'Suppliers List'" />
      

      <div class="mt-2 mt-lg-3 row attendance-head">
        <div class="col-md-8">
          <!-- <InventorySearch
            v-on:childToParent="searchEvent"
            :searchColumns="searchColumns"
          /> -->
          <resueable-search
             @childToParent="prepareList"
            :url="urlToFetchOrders"
            :showBranch="false"
            :showDate="false"
          >
          <template #default= "{ searchQuery }">
                <div class="col-md">
                    <div>
                    <label class="form-control-label">Name:  </label>
                    </div>
                    <input type="text" v-model="searchQuery.name" class="form-control">
                </div>                
            </template>
          </resueable-search>
          <!-- <payment-filter  :disabled="false"></payment-filter> -->
        </div>

        <div class="col-md-4">
          <router-link :to="{ name: 'suppliersCreate' }">
            <button class="btn btn-primary bg-default myBtn float-right my-2">
              <i class="fa fa-plus" aria-hidden="true"></i>
            </button>
          </router-link>
        </div>
        
      </div>

      <div class="mt-5 mb-3 attendance-head">
        <div class="w-100 my-5 mx-0 hr"></div>
        <div class="row px-4 pt-3 pb-4 text-center">
          <div class="col light-heading" style="max-width: 120px">S/N</div>
          <div class="col light-heading" v-for="header in headings">
            {{ header }}
          </div>
        </div>
      </div>
      <div class="tab-content mt-1 attendance-body">
        <div
          class="mb-3 row attendance-item"
          :key="index"
          v-for="(supplier, index) in suppliers"
          @click="viewSupplier(supplier)"
        >
          <div class="col d-flex align-items-center" style="max-width: 120px">
            <span class="user mx-auto">{{ index + OId }}</span>
          </div>
          <div class="col d-flex align-items-center justify-content-center">
            {{ supplier.name | capitalize }}
          </div>
          <div class="col d-flex align-items-center justify-content-center">
            {{ supplier.sku }}
          </div>
          <div class="col d-flex align-items-center justify-content-center">
            {{ supplier.email }}
          </div>
          <div class="col d-flex align-items-center justify-content-center">
            {{ supplier.phone_number }}
          </div>
          <div class="col d-flex align-items-center justify-content-center">
            {{ supplier.contact_person_name | capitalize }}
          </div>
        </div>
      </div>
      <div class="modal fade repayment" id="viewSupplier">
        <div class="modal-dialog" role="document">
          <div class="modal-content" v-if="showModalContent">
            <div class="modal-header py-2">
              <h4>{{ supplierItem.name }}</h4>
              <a aria-label="Close" class="close py-1" data-dismiss="modal">
                <span aria-hidden="true" class="modal-close text-danger">
                  <i class="fas fa-times"></i>
                </span>
              </a>
            </div>
            <div class="modal-body px-5">
              <div class="table-responsive">
                <table class="table table-bordered table-striped">
                  <tbody>
                    <tr>
                      <th>Name</th>
                      <td>{{ supplierItem.name || "Not Available" }}</td>
                    </tr>

                    <tr>
                      <th>SKU</th>
                      <td>{{ supplierItem.sku || "Not Available" }}</td>
                    </tr>

                    <tr>
                      <th>City</th>
                      <td>{{ supplierItem.city || "Not Available" }}</td>
                    </tr>
                    <tr>
                      <th>State</th>
                      <td>{{ supplierItem.state || "Not Available" }}</td>
                    </tr>
                    <tr>
                      <th>Contact</th>
                      <td>
                        {{
                          supplierItem.contact_person_name || "Not Available"
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th>Phone</th>
                      <td>
                        {{ supplierItem.phone_number || "Not Available" }}
                      </td>
                    </tr>

                    <tr>
                      <th>Email</th>
                      <td>{{ supplierItem.email || "Not Available" }}</td>
                    </tr>
                    <tr>
                      <th>Bank Account Name</th>
                      <td>
                        {{ supplierItem.bank_account_name || "Not Available" }}
                      </td>
                    </tr>
                    <tr>
                      <th>Bank Account Number</th>
                      <td>
                        {{ supplierItem.bank_account_no || "Not Available" }}
                      </td>
                    </tr>
                    <tr>
                      <th>Status</th>
                      <td>{{ supplierItem.is_active | status }}</td>
                    </tr>

                    <tr>
                      <th>Date</th>
                      <td>
                        {{
                          supplierItem.created_at
                            ? supplierItem.created_at.split(" ")[0]
                            : "Not Available"
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th>Time</th>
                      <td>
                        {{
                          supplierItem.created_at
                            ? supplierItem.created_at.split(" ")[1]
                            : "Not Available"
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="modal-footer justify-content-center">
              <button
                class="text-center btn bg-default"
                @click="edit(supplierItem.id)"
              >
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="pageParams">
        <base-pagination
          :page-param="pageParams"
          :page="page"
          @fetchData="fetchData()"
          @next="next()"
          @prev="prev()"
        >
        </base-pagination>
      </div>
    </div>
  </transition>
</template>
<script>
import { get } from "../../../utilities/api";
import Flash from "../../../utilities/flash";

import { mapGetters, mapActions } from "vuex";
import CustomHeader from "../../../components/customHeader";
import BasePagination from "../../../components/Pagination/BasePagination";
import InventorySearch from "../../../components/InventorySearch";
import ResueableSearch from "../../../components/ReusableSearch";
import PaymentFilter from "../../../components/PaymentFilter";

export default {
  props: {
    //TODO::verify if its necessary to make this a prop
    withBranchFilter: { default: true },
    urlToFetchOrders: { default: "/api/supplier" },
  },

  components: {
    CustomHeader,
    BasePagination,
    InventorySearch,
    ResueableSearch,
    PaymentFilter,
  },

  computed: { ...mapGetters(["getStates"]) },

  data() {
    return {
      branch_id: "",
      OId: null,
      showModalContent: false,
      pageParams: null,
      page_size: 10,
      date_from: null,
      date_to: null,
      page: 1,
      filters: [],
      suppliers: null,
      supplierItem: null,
      response: {},
      show: false,
      headings: ["Name", "SKU", "Email", "Phone Number", "Contact Person"],
      searchColumns: [
        { title: "Phone Number", checked: false, column: "phoneNumber" },
        { title: "Name", checked: false, column: "name" },
      ],
    };
  },

  methods: {
    fetchData() {
      this.$scrollToTop();
      this.$LIPS(true);
      let { page, page_size } = this.$data;
      get(
        this.urlToFetchOrders +
          `${!!page ? `?page=${page}` : ""}` +
          `${!!page_size ? `&pageSize=${page_size}` : ""}`
      )
        .then(({ data }) => this.prepareList(data))
        .catch(() => Flash.setError("Error Preparing form"));
    },

    prepareList(response) {
      let {
        current_page,
        first_page_url,
        from,
        last_page,
        last_page_url,
        data,
        per_page,
        next_page_url,
        to,
        total,
        prev_page_url,
      } = response.data;
      this.pageParams = Object.assign({}, this.pageParams, {
        current_page,
        first_page_url,
        from,
        last_page,
        last_page_url,
        per_page,
        next_page_url,
        to,
        total,
        prev_page_url,
      });
      this.suppliers = data;
      this.OId = from;
      this.$LIPS(false);
    },

    next(firstPage = null) {
      if (this.pageParams.next_page_url) {
        this.page = firstPage ? firstPage : parseInt(this.page) + 1;
        this.fetchData();
      }
    },

    prev(lastPage = null) {
      if (this.pageParams.prev_page_url) {
        this.page = lastPage ? lastPage : parseInt(this.page) - 1;
        this.fetchData();
      }
    },

    viewSupplier(supplier) {
      this.showModalContent = true;
      this.supplierItem = supplier;
      return $(`#viewSupplier`).modal("toggle");
    },
    edit(item) {
      this.showModalContent = false;
      $(`#viewSupplier`).modal("toggle");

      return this.$router.push({ name: "suppliersEdit", params: { id: item } });
    },

    searchEvent(data) {
      get(this.urlToFetchOrders + data)
        .then(({ data }) => this.prepareList(data))
        .catch(() => Flash.setError("Error Preparing form"));
    },

    ...mapActions("ModalAccess", [
      "addCustomerOptionsModalsToDom",
      "removeCustomerOptionsModalsFromDom",
    ]),
  },

  created() {
    this.$props.withBranchFilter &&
      this.filters.unshift({ name: "state", model: "state_id" });
    this.addCustomerOptionsModalsToDom();

    this.$prepareStates();
    this.fetchData();
  },

  filters: {
    status: function (value) {
      if (value === 1) {
        return "Active";
      } else return "Inactive";
    },
  },
};
</script>

<style scoped>
.flex-row-bottom {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}
.green {
  color: green;
}
.red {
  color: red;
}
</style>
